<template>
  <v-container>
    <v-row>
      <v-col>
        <v-row v-show="!ufo">
    <v-col cols="12">
      <v-snackbar v-model="snackbar" top :timeout="3000">{{ errorMessage }}</v-snackbar>
      <v-form ref="form" v-on:submit.prevent="post()">
      <v-stepper v-model="step" vertical>
        <v-stepper-step editable  step="1" complete>Téléchargement du fichier</v-stepper-step>
        <v-stepper-content step="1">
          <v-card class="mb-2 elevation-0"><p><v-icon>mdi-information</v-icon>
          Formats acceptés : <a @click="help = true">voir la liste</a></p>
              <v-file-input
                v-model="file" how-size label="Fichier" @change="onFileChange">
              </v-file-input>
            </v-card>
          <v-btn color="primary" @click="step = 2">Suivant</v-btn>
        </v-stepper-content>

        <v-stepper-step editable step="2">Choix des analyses</v-stepper-step>
        <v-stepper-content step="2">
          <v-card class="elevation-0 mb-2">
            <v-list>
              <v-list-item @click="toggleSelect()" >
                <v-list-item-content>
                  <v-list-item-title>Sélectionner tout</v-list-item-title>
                  </v-list-item-content>
              </v-list-item>
              <v-list-item-group v-model="options" multiple>
                <template v-for="(item, i) in items">
                  <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                  <v-list-item
                    v-else
                    :key="`item-${i}`"
                    :value="item.value"
                    :disabled="item.disabled"
                    >
                    <template v-slot:default="{ active }">
                      <v-list-item-content>
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                        <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action>
                    </template>
                  </v-list-item>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card>
          <v-btn color="primary" @click="step = 3">Suivant</v-btn>
        </v-stepper-content>
        <v-stepper-step editable step="3">Format de sortie</v-stepper-step>
        <v-stepper-content step="3">
          <v-card class="elevation-0 mb-2">
              <p class="body-1">Sélectionnez le format de sortie  </p>
                <v-list-item-group v-model="format">
                  <template v-for="(item, i) in types">
                    <v-divider v-if="!item" :key="`divider-${i}`"></v-divider>
                    <v-list-item
                      v-else
                      :key="`item-${i}`"
                      :value="item.value"
                      :disabled="item.disabled"
                      >
                      <template v-slot:default="{ active }">
                        <v-list-item-content>
                          <v-list-item-title v-text="item.title"></v-list-item-title>
                          <v-list-item-subtitle v-text="item.description"></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          <v-checkbox :input-value="active"></v-checkbox>
                        </v-list-item-action>
                      </template>
                    </v-list-item>
                  </template>
                </v-list-item-group>
                <v-card-actions>
                  <v-btn :disabled="!formIsValid || loading" color="primary" type="submit">Envoyer</v-btn>
                  <v-btn v-show="loading" color="error" @click="abort()">Annuler</v-btn>
                </v-card-actions>
          </v-card>
        </v-stepper-content>
      </v-stepper>
    </v-form>

    <v-progress-linear v-show="loading" indeterminate></v-progress-linear>
    <v-dialog v-model="help" scrollable max-width="50%">
      <v-card>
        <v-card-title>Formats acceptés</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <HelpContent/>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="blue darken-1" text @click="help = false">Fermer</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    </v-col>
  </v-row>
</v-col>
<v-col>
  <v-row v-show="ufo">
    <v-col>
      <p class="subtitle-2">Aperçu des résultats d'analyses (format
        <a target="_blank" href="https://slodim.gitlabpages.inria.fr/slodim-ufo/">UFO</a>)
      </p>
       <a @click="txt(conllu, 'slodim.conllu')"><v-icon color="indigo">mdi-download</v-icon>Télécharger CONLLU</a> |
       <a @click="txt(ufo, 'slodim.ufo')"><v-icon color="blue">mdi-download</v-icon>Télécharger UFO</a> |
       <a @click="reset()">Retour</a><br/><br/>
         <pre >{{ ufo.slice(0, 2048) }}...</pre><br/><br/>
         <a @click="txt(conllu, 'slodim.conllu')"><v-icon color="indigo">mdi-download</v-icon>Télécharger CONLLU</a> |
         <a @click="txt(ufo, 'slodim.ufo')"><v-icon color="blue">mdi-download</v-icon>Télécharger UFO</a> |
       <a @click="reset()">Retour</a>
    </v-col>
  </v-row>
</v-col>
</v-row>
</v-container>
</template>


<script>

import HelpContent from './HelpContent.vue';

  export default {
    name: 'StepperTool',
    components: { HelpContent },
    computed: {
      formIsValid() {
        return this.options.length > 0 && this.file && this.format;
      },
    },

    mounted() {
      this.reset();
    },

    methods: {
      abort() {
        this.controller.abort();
      },

      async post() {
        const defaultHeaders = {
          'Client-Application-Version': '1.0',
          'Client-Application-Name': 'slodim',
        };
        const apiRoot = process.env.VUE_APP_API_ROOT;
        this.loading = true;
        this.formData.delete('options');
        this.formData.append('options', this.options);
        this.formData.delete('format');
        this.formData.append('format', this.format);

        try {
          const controller = new AbortController();
          this.controller = controller;
          const signal = this.controller.signal;
          const response = await fetch(`${apiRoot}/analysis`, { method: 'POST', headers: defaultHeaders, body: this.formData, signal });
          const data = await response.json();

          if (data.success) {
            this.ufo = data.ufo;
            this.conllu = data.conllu;
            if(this.format === 'pdf') {
              this.pdf(data.data);
            } else if (this.format == 'txt') {
              this.txt(data.data, 'slodim-report.txt');
            } else if (this.format == 'latex') {
              this.txt(data.data, 'slodim-report.tex');
            }
            else {
              this.json(data.data);
            }
          } else {
            this.snackbar = true;
            this.errorMessage = data.msg;
          }
          this.loading = false;
        } catch(e) {
          if (e.name === 'AbortError') {
            this.snackbar = true;
            this.errorMessage = "Opération annulée";
            this.reset();
          }
        }
      },


      onFileChange(e) {
        this.formData = new FormData();
        this.formData.append('file', e);
      },

      reset() {
        this.step = 1;
        this.options = [];
        this.format= 'pdf';
        this.ufo = '';
        this.loading = false;
        this.file = null;
      },

      download(name, data, type) {
        const url = URL.createObjectURL(new Blob([data]), { type });
        const link = document.createElement('a');
        link.href = url;
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      },

      pdf(data) {
        this.loading = true;
        const buf = data.substring(1).replaceAll('\'', '');
        this.download('report.pdf', atob(buf), 'application/pdf');
        this.loading = false;
      },

      json(data) {
        this.loading = true;
        this.download('slodim-report.json', JSON.stringify(data, null, ' '), 'application/json');
        this.loading = false;
      },

      txt(data, name) {
        this.loading = true;
        this.download(name, data, 'application/txt');
        this.loading = false;
      },


      toggleSelect() {
        const enabled = this.items.filter((x) => !x.disabled);
        if (this.options.length < enabled.length) {
          this.options = enabled.map((x) => x.value);
        } else {
          this.options = [];
        }
      },
    },

    data: () => ({
      step: 1,
      options: [],
      formData: null,
      format: 'pdf',
      ufo: '',
      conllu: '',
      loading: false,
      file: null,
      help: false,
      snackbar: false,
      errorMessage: '',
      controller: null,
      items: [
        { value: '--raw-basics', title: 'Données brutes', description: 'Nombre de tours, mots, nombre moyen mots/tour, longueur moyenne des mots, ratio stopwords' },
        { value: '--raw-advanced', title: 'Données brutes avancées', description: 'Évolution de la longueur des mots, nombre de lemmes/formes, POS-Tag' },
        { value: '--syntax-basics', title: 'Syntaxe', description: 'Densité lexicale, nombre de questions, subordonnées, arité verbale' },
        { value: '--syntax-advanced', title: 'Syntaxe avancée', description: 'Données sur les relations syntaxiques' },
        { value: '--disfluences', title: 'Disfluences', description: 'Nombre de disfluences et leurs types' },
        { value: '--deictics', title: 'Déictiques', description: 'Nombre de déictiques et leur types' },
        { value: '--dialogic', title: 'Analyse dialogique', description: 'Transactions, questions/réponses', disabled: true },
        { value: '--discursive', title: 'Analyse discursive', disabled: true },
      ],
      types: [
        { value: 'pdf', title: 'PDF', description: 'Rapport au format PDF, inclut histogrammes et distributions' },
        { value: 'json', title: 'JSON', description: 'Rapport au format JSON' },
        { value: 'txt', title: 'Texte', description: 'Mise en page au format texte, sans les diagrammes' },
        { value: 'latex', title: 'LaTeX', description: 'Scripts LaTeX, sans les diagrammes' },

      ],
    }),
  }
</script>
