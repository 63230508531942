<template>
  <v-row>
    <v-col>
      <p class="title">Brut</p>
      <p class="body-1">1 tour de parole par ligne préfixé par le locuteur (export Transcriber).</p>
      <p>
      <pre class="grey lighten-3">
      Y:oui
      R:euh
      Y:alors euh...</pre></p>
      <p class="title">Export Elan</p>
      <p class="body-1">Format texte généré par le logiciel Elan, exemple:</p>
      <p><pre class="grey lighten-3">
      0581 Y   oui
               00:18:12.167 - 00:18:12.438
               (0.97)

      0582 R   euh
               00:18:13.407 - 00:18:16.110
               (1.10)</pre></p>

      <p class="title">UFO</p>
      <p class="body-1">La description du format est disponible
        <a target="_blank" href="https://slodim.gitlabpages.inria.fr/slodim-ufo/">en cliquant ici</a>
      </p>

      <p class="title">TRS</p>
      <p class="body-1">Fichier généré par le logiciel Transcriber sans export préablable.<p>
      <p class="body-1"><v-icon>mdi-alert</v-icon> <b>Attention la prise en charge de ce format peut-être incompléte.</b></p>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'HelpContent',
};
</script>
