<template>
  <v-container>
    <v-row>
      <v-col>
        <p class="title">L'action exploratoire</p>
        <p class="subtitle-2">Description</p>
        <p class="body-1">ODiM est un projet interdisciplinaire, à l’interface de : la psychiatrie-psychopathologie, la linguistique, la sémantique formelle et les sciences du numérique.
 Il tend à remplacer le paradigme des Troubles du Langage et de la Pensée (TLP) tel qu’on l’utilise dans le secteur de la Santé mentale par un modèle sémantico-formel des Troubles du Discours (TDD). Il s’agit de traduire ces troubles en signes diagnostiques ainsi que de dépistage
 des personnes vulnérables dites "à risques".</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="d-flex justify-center">
        <v-img src="../assets/home.svg" class="mt-5" max-width="230px"></v-img>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'Landing',
  data: () => ({}),
};
</script>
