<template>
  <v-app>
    <v-app-bar app color="white" flat dense>
      <v-toolbar-title light class="font-weight-bold blue--text">SLODiM
        <span class="body-1 teal--text">|</span>
        <span class="teal--text subtitle-2">Academics</span>
      </v-toolbar-title>
    </v-app-bar>
    <v-main>
      <v-container>
        <v-row>
          <v-col cols="5">
            <Landing/>
          </v-col>
          <v-col cols="7">
            <StepperTool/>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Landing from './components/Landing.vue';
import StepperTool from './components/StepperTool.vue';

export default {
  name: 'App',
  components: {
    Landing,
    StepperTool,
  },
};
</script>
